import style from "./Home.module.css";
import logochip from "../../Assets/img/logochip.png";
import tiktok from "../../Assets/img/tiktok.png";
import instagram from "../../Assets/img/instagram.png";
import facebook from "../../Assets/img/facebook.png";
import whatsapp from "../../Assets/img/whatsapp.png";
import tiktokA from "../../Assets/img/tiktokA.png";
import instagramA from "../../Assets/img/instagramA.png";
import facebookA from "../../Assets/img/facebookA.png";
import whatsappA from "../../Assets/img/whatsappA.png";
import check from "../../Assets/img/check.gif";
import checkS from "../../Assets/img/checkS.png";
import collage from "../../Assets/img/collage.png";
import desarrolloWeb from "../../Assets/img/desarrolloWeb.png";
import landingPages from "../../Assets/img/landingPages.png";
import e_commerce from "../../Assets/img/e_commerce.png";
import seo from "../../Assets/img/seo.png";
import whatsappS from "../../Assets/img/whatsappS.png";
import { useState } from "react";
import Footer from "./Footer/Footer";

const Home = () => {

    const [srcTiktok, setSrcTiktok] = useState(tiktok);
    const [srcInstagram, setSrcInstagram] = useState(instagram);
    const [srcFacebook, setSrcFacebook] = useState(facebook);
    const [srcWhatsapp, setSrcWhatsapp] = useState(whatsapp);
    const [srcImagenServicios, setSrcImagenServicios] = useState(collage);

    return (
        <div className={style.Home}>
            <div className={style.presentacion}>
                <div className={style.presentacion1}>
                    <img src={logochip} alt="logochip" className={style.logochip} />
                    <div className={style.contLogos}>
                        <a href="https://www.instagram.com/chip_tecnologia/"
                            className={style.aTiktok}
                            target="_blank"
                            onMouseOver={() => setSrcTiktok(tiktokA)}
                            onMouseOut={() => setSrcTiktok(tiktok)}
                        >
                            <img src={srcTiktok} alt="tiktok" className={style.tiktok} />
                        </a>
                        <a href="https://www.instagram.com/chip_tecnologia/"
                            className={style.aInstagram}
                            target="_blank"
                            onMouseOver={() => setSrcInstagram(instagramA)}
                            onMouseOut={() => setSrcInstagram(instagram)}
                        >
                            <img src={srcInstagram} alt="instagram" className={style.instagram} />
                        </a>
                        <a href="https://www.facebook.com/chiptecnologia"
                            className={style.aFacebook}
                            target="_blank"
                            onMouseOver={() => setSrcFacebook(facebookA)}
                            onMouseOut={() => setSrcFacebook(facebook)}
                        >
                            <img src={srcFacebook} alt="facebook" className={style.facebook} />
                        </a>
                        <a href="https://wa.link/qp7jo2"
                            className={style.aWhatsapp}
                            target="_blank"
                            onMouseOver={() => setSrcWhatsapp(whatsappA)}
                            onMouseOut={() => setSrcWhatsapp(whatsapp)}
                        >
                            <img src={srcWhatsapp} alt="whatsapp" className={style.whatsapp} />
                        </a>
                    </div>
                </div>
                <div className={style.presentacion2}>
                    <h3 className={style.h3Creamos}>Creamos tu</h3>
                    <h1 className={style.h1Pagina}>PAGINA WEB</h1>
                    <h3 className={style.h3Impulsamos}>¡Impulsamos tus ideas en el mundo digital!</h3>
                </div>
            </div>
            <div className={style.servicios}>
                <div className={style.servicios0}>
                    <h1 className={style.titulo}>SERVICIOS...</h1>
                </div>
                <div className={style.contServicios}>
                    <div className={style.servicios1}>
                        <div
                            className={style.servicio}
                            onMouseOver={() => setSrcImagenServicios(desarrolloWeb)}
                            onMouseOut={() => setSrcImagenServicios(collage)}
                        >
                            <img src={check} alt="check" className={style.check} />
                            <img src={checkS} alt="checkS" className={style.checkS} />
                            <div className={style.cuadro}></div>
                            <h3 className={style.h3servicio}>Desarrollo Web</h3>
                        </div>
                        <div
                            className={style.servicio}
                            onMouseOver={() => setSrcImagenServicios(landingPages)}
                            onMouseOut={() => setSrcImagenServicios(collage)}
                        >
                            <img src={check} alt="check" className={style.check} />
                            <img src={checkS} alt="checkS" className={style.checkS} />
                            <div className={style.cuadro}></div>
                            <h3 className={style.h3servicio}>Landing pages</h3>
                        </div>
                        <div
                            className={style.servicio}
                            onMouseOver={() => setSrcImagenServicios(e_commerce)}
                            onMouseOut={() => setSrcImagenServicios(collage)}
                        >
                            <img src={check} alt="check" className={style.check} />
                            <img src={checkS} alt="checkS" className={style.checkS} />
                            <div className={style.cuadro}></div>
                            <h3 className={style.h3servicio}>E-commerce</h3>
                        </div>
                        <div
                            className={style.servicio}
                            onMouseOver={() => setSrcImagenServicios(seo)}
                            onMouseOut={() => setSrcImagenServicios(collage)}
                        >
                            <img src={check} alt="check" className={style.check} />
                            <img src={checkS} alt="checkS" className={style.checkS} />
                            <div className={style.cuadro}></div>
                            <h3 className={style.h3servicio}>SEO</h3>
                        </div>
                    </div>
                    <div className={style.servicios2}>
                        <img
                            src={srcImagenServicios}
                            alt="desarrolloWeb"
                            className={style.imagenServicio}
                        />
                    </div>
                </div>
                <a href="https://wa.link/qp7jo2" className={style.aLogoWhatsapp} target="_blank">
                    <img src={whatsappS} alt="logoWhatsapp" className={style.logoWhatsapp}/>
                </a>
            </div>
            <Footer/>
        </div>
    )
};

export default Home