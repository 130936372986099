import { useEffect, useState } from "react";
import style from "./Form.module.css";
import Swal from "sweetalert2";

const Form = ({ setShowForm }) => {
    const [textAlert, setTextAlert] = useState("");
    const [inputValueName, setInputValueName] = useState('');
    const [inputValueTel, setInputValueTel] = useState('');
    const [inputValueCorreo, setInputValueCorreo] = useState('');
    const [inputValueMensaje, setInputValueensaje] = useState('');

    const showAlertOk = () => {
        const mayus = inputValueName.toUpperCase()
        Swal.fire({
            title: 'Datos enviados!',
            text: `Gracias ${mayus}, responderemos a su solicitud en el menor tiempo posible`,
            icon: 'success',
            confirmButtonText: 'Aceptar'
        })
    };

    const showAlertFail = () => {
        Swal.fire({
            title: '¡Revisa la informacion!',
            text: textAlert,
            icon: 'warning',
            confirmButtonText: 'Revisar'
        });
        setTextAlert("")
    };

    const handleChange = (e) => {
        if (e.target.id == "nombre") setInputValueName(e.target.value);
        if (e.target.id == "telefono") setInputValueTel(e.target.value);
        if (e.target.id == "correo") setInputValueCorreo(e.target.value);
        if (e.target.id == "mensaje") setInputValueensaje(e.target.value);
    };

    const valNombre = () => {
        let isValid = /^.{4,19}$/.test(inputValueName);
        if (!isValid) setTextAlert("Ingresa tu NOMBRE con mas de 3 caracteres");
        return isValid
    };
    const valTel = () => {
        let isValid = /^\d{5,15}$/.test(inputValueTel)
        if (!isValid) setTextAlert("Ingresa tu número de TELEFONO con mas de 5 caracteres");
        return isValid
    };
    const valCorreo = () => {
        let isValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(inputValueCorreo)
        if (!isValid) setTextAlert("Ingresa una dirección de CORREO electrónico válida");
        return isValid
    };

    const soloNumeros = (event) => {
        const key = event.which || event.keyCode;
        if (key < 48 || key > 57) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        if (textAlert.length > 0) showAlertFail()
    }, [textAlert])

    return (
        <div className={style.Form}>
            <div className={style.nombre}>
                <h2 className={style.hTitulo}>Nombre:</h2>
                <input
                    type="text"
                    className={style.input}
                    placeholder="ingresa tu nombre aquí..."
                    id="nombre"
                    value={inputValueName}
                    onChange={handleChange}
                />
            </div>
            <div className={style.telefono}>
                <h2 className={style.hTitulo}>Telefono:</h2>
                <input
                    onKeyPress={soloNumeros}
                    type="text"
                    className={style.input}
                    placeholder="ingresa tu número de teléfono aquí..."
                    id="telefono"
                    value={inputValueTel}
                    onChange={handleChange}
                />
            </div>
            <div className={style.correo}>
                <h2 className={style.hTitulo}>Correo:</h2>
                <input
                    type="text"
                    className={style.input}
                    placeholder="ingresa tu correo electrónico aquí..."
                    id="correo"
                    value={inputValueCorreo}
                    onChange={handleChange}
                />
            </div>
            <div className={style.mensaje}>
                <h2 className={style.hTitulo}>Mensaje:</h2>
                <textarea
                    name="mensaje"
                    id="mensaje"
                    className={style.textarea}
                    placeholder="Déjanos tu mensaje aquí para una atencion mas rápida..."
                    value={inputValueMensaje}
                    onChange={handleChange}
                >
                </textarea>
            </div>
            <div className={style.enviar}>
                <button
                    className={style.boton}
                    onClick={() => {
                        if (valNombre() && valTel() && valCorreo()) {
                            const info = {
                                nombre: inputValueName,
                                tel: inputValueTel,
                                correo: inputValueCorreo,
                                mensaje: inputValueMensaje
                            }
                            console.log(JSON.stringify(info));                                                //!   QUITAR ESTE LOG AL FINAL
                            showAlertOk();
                            setShowForm();
                        }
                    }}
                >
                    Enviar
                </button>
            </div>
        </div>
    )
};

export default Form;