import style from "./Footer.module.css";
import tel from "../../../Assets/img/tel.png";
import flecha from "../../../Assets/img/flecha.png";
import { useState } from "react";
import tiktok from "../../../Assets/img/tiktok.png";
import instagram from "../../../Assets/img/instagram.png";
import facebook from "../../../Assets/img/facebook.png";
import whatsapp from "../../../Assets/img/whatsapp.png";
import tiktokA from "../../../Assets/img/tiktokA.png";
import instagramA from "../../../Assets/img/instagramA.png";
import facebookA from "../../../Assets/img/facebookA.png";
import whatsappA from "../../../Assets/img/whatsappA.png";
import Form from "./Form/Form";

const Footer = () => {

    const [hover, setHover] = useState(false);
    const [srcTiktok, setSrcTiktok] = useState(tiktok);
    const [srcInstagram, setSrcInstagram] = useState(instagram);
    const [srcFacebook, setSrcFacebook] = useState(facebook);
    const [srcWhatsapp, setSrcWhatsapp] = useState(whatsapp);
    const [showForm, setShowForm] = useState(false);

    return (
        <div className={style.Footer}>
            <div className={style.contacto}>
                <h1 className={style.titulo}>CONTACTO...</h1>
            </div>
            <div className={style.numero}>
                <img src={tel} alt="tel" className={style.tel} />
                <a href="tel:+573006121155" className={style.ah2tel}>
                    <h2 className={style.h2tel}>300 6121155</h2>
                </a>
            </div>
            <div className={style.escribenos} id="escribenos">
                <a
                    href="#escribenos"
                    className={style.aHEscribenos}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    onClick={() => setShowForm(!showForm)}
                    style={{ visibility: showForm ? "hidden" : "" }}
                >
                    <h2
                        className={style.hEscribenos}
                        style={{ textDecoration: hover ? "underline" : "" }}
                    >
                        Escríbenos
                    </h2>
                    {!showForm && <img src={flecha} alt="flecha" className={style.flecha} />}
                </a>
                    {showForm && <img src={flecha} alt="flechaV" className={style.flechaV} onClick={() => setShowForm(false)}/>}
                {showForm &&
                    <Form setShowForm={setShowForm}/>
                }
            </div>
            <div className={style.redes}>
                <h3 className={style.hCopy}>Copyright © 2024 Chip Desarrollo Web</h3>
                <div className={style.contLogos}>
                    <a 
                        href="https://www.instagram.com/chip_tecnologia/"
                        className={style.aTiktok}
                        target="_blank"
                        onMouseOver={() => setSrcTiktok(tiktokA)}
                        onMouseOut={() => setSrcTiktok(tiktok)}
                    >
                        <img src={srcTiktok} alt="tiktok" className={style.tiktok} />
                    </a>
                    <a 
                        href="https://www.instagram.com/chip_tecnologia/"
                        className={style.aInstagram}
                        target="_blank"
                        onMouseOver={() => setSrcInstagram(instagramA)}
                        onMouseOut={() => setSrcInstagram(instagram)}
                    >
                        <img src={srcInstagram} alt="instagram" className={style.instagram} />
                    </a>
                    <a
                        href="https://www.facebook.com/chiptecnologia"
                        className={style.aFacebook}
                        target="_blank"
                        onMouseOver={() => setSrcFacebook(facebookA)}
                        onMouseOut={() => setSrcFacebook(facebook)}
                    >
                        <img src={srcFacebook} alt="facebook" className={style.facebook} />
                    </a>
                    <a 
                        href="https://wa.link/qp7jo2"
                        className={style.aWhatsapp}
                        target="_blank"
                        onMouseOver={() => setSrcWhatsapp(whatsappA)}
                        onMouseOut={() => setSrcWhatsapp(whatsapp)}
                    >
                        <img src={srcWhatsapp} alt="whatsapp" className={style.whatsapp} />
                    </a>
                </div>
            </div>
        </div>
    )
};

export default Footer;